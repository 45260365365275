import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setInstallationAddress, setIsSameAddress } from '../actions';
import { renderInstallationForms, renderSameAddressCheckbox } from '../selectors';
import Guard from '../controls/Guard';
import RadioChoice from '../controls/RadioChoice';
import AddressForm from '../controls/AddressForm';
import i18n from "../i18n";

const sameAddressChoices = [
    { label: 'yes', value: true },
    { label: 'no', value: false },
];

const InstallationAddress = () => {
    const dispatch = useDispatch();
    // Don't render same-address on the professional portal
    const renderSameAddress = useSelector(renderSameAddressCheckbox);
    const isSameAddress = useSelector(store => store.form.isSameAddress);
    const isSameAddressError = useSelector(store => store.errors.isSameAddress);
    const installationAddress = useSelector(store => store.form.installationAddress);
    const installationAddressErrors = useSelector(store => store.errors.installationAddress);
    return (
        <section className='form-section'>
            {renderSameAddress && (
                <RadioChoice
                    id='installation_address_same'
                    label='installation_address_same'
                    choices={sameAddressChoices}
                    onChange={value => dispatch(setIsSameAddress(value))}
                    selectedValue={isSameAddress}
                    error={isSameAddressError}
                    required
                />
            )}
            {(!renderSameAddress || isSameAddress === false) && (
                <>
                    <h2 className='form-section-title'>{i18n('installation_address')}</h2>
                    <AddressForm
                        id='installation-address'
                        address={installationAddress}
                        errors={installationAddressErrors}
                        onChange={address => dispatch(setInstallationAddress(address))}
                    />
                </>
            )}
        </section>
    );
};

export default () => <Guard selector={renderInstallationForms}><InstallationAddress /></Guard>;
