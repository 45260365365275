/* eslint-disable func-names */
import boiler from '@boiler';
import { createLogicMiddleware } from 'redux-logic';
import App from './App';
import reducers from './reducers';
import logic from './logic';
import i18n from './i18n';
import { forceInstallationViaSerial, createInstallationName } from './selectors';
import { defaultState as defaultFormState, VISIT_TYPES } from './reducers/formReducer';
import { defaultState as defaultSettingsState, SESSION_USER_TYPES } from './reducers/settingsReducer';

// Strip out unwanted values & map some settings to form-state
const mapInputToState = input => {
    const { labels, settings } = input;
    // Pass labels to i18n
    i18n.set(labels);
    // Create initial state
    const returnState = {
        settings: { ...defaultSettingsState, ...settings },
        form: { ...defaultFormState },
    };
    // Check settings
    let { installation } = settings;
    const { installations, userType, customer } = settings;
    // Autoselect installation
    if (installations?.length) installation = installations[0];
    // Default visitType is repair on pro-portal
    const isPro = userType === SESSION_USER_TYPES.PRO;
    const isEnd = userType === SESSION_USER_TYPES.END;
    returnState.form.visitType = isPro ? VISIT_TYPES.REPAIR : null;
    returnState.form.hasExistingInstallation = isPro || isEnd;
    // Germany & France is always via installation-serial
    if (forceInstallationViaSerial(returnState)) returnState.form.installationSerialKnown = true;
    // Pass existing installation to form
    if (installation) {
        const { id, customerInvitationDetails, keyAccountInvitationDetails } = installation;
        returnState.form.hasExistingInstallation = true;
        returnState.form.existingInstallation = {
            id,
            name: createInstallationName(installation),
        };
        // Pass customerDetails to form
        returnState.form.contactPersonDetails = {
            firstName: customerInvitationDetails?.firstName || null,
            lastName: customerInvitationDetails?.lastName || null,
            email: customerInvitationDetails?.email || keyAccountInvitationDetails?.email || null,
            phone: customerInvitationDetails?.phoneNumber || null,
        };
    }
    // Prefill end-user data with logged in customer data
    if (customer && userType === SESSION_USER_TYPES.END) {
        returnState.form.userDetails = {
            firstName: customer.firstName || null,
            lastName: customer.lastName || null,
            email: customer.email || null,
            phone: customer.phoneNumber || null,
        };
    }
    return returnState;
};

(function () {
    boiler.initAppInstances(
        App,
        'rfa-form',
        reducers,
        mapInputToState,
        [createLogicMiddleware(logic)],
    );
}());
