import React, { useRef, useImperativeHandle } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setHasSbmAccount } from '../actions';
import { renderHasAccount } from '../selectors';
import RadioChoice from '../controls/RadioChoice';
import Guard from '../controls/Guard';
import i18n from '../i18n';

const hasSbmAccountChoices = [
    { label: 'yes', value: true },
    { label: 'no', value: false },
];

let signInElement = document.querySelectorAll('div.header-account.not-logged-in')[0];
if (signInElement) signInElement = signInElement.cloneNode(true);

const HasSbmAccount = () => {
    const dispatch = useDispatch();
    const hasSbmAccount = useSelector(store => store.form.hasSbmAccount);
    const signInElementRef = useRef(null);
    useImperativeHandle(signInElementRef, () => {
        if (signInElementRef.current) signInElementRef.current.appendChild(signInElement);
    });
    return (
        <>
            <RadioChoice
                id='has_sbm_account'
                label='has_sbm_account'
                choices={hasSbmAccountChoices}
                onChange={value => dispatch(setHasSbmAccount(value))}
                selectedValue={hasSbmAccount}
                required
            />
            {hasSbmAccount && (
                <div className='panel'>
                    <h4>{i18n('sign_in')}</h4>
                    <div className='grid-row'>
                        {signInElementRef && <div className='sign-in-element' ref={signInElementRef} />}
                        <div className='grid-col-6 grid-col-lg-6'>
                            {i18n('sign_in_info')}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default () => <Guard selector={renderHasAccount}><HasSbmAccount /></Guard>;
