import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { queryMaterials, setInstallationDetailsViaSerial } from '../actions';
import { useDelayedFormChangeDispatch } from '../hooks';
import i18n from '../i18n';
import Typeahead from '../controls/Typeahead';
import ProductRange from '../controls/ProductRange';
import InputWrapper from '../controls/InputWrapper';

const InstallationDetailsViaSerial = () => {
    const dispatch = useDispatch();
    const onChange = changes => dispatch(setInstallationDetailsViaSerial(changes));
    const suggestions = useSelector(store => store.materials.suggestions || []);
    const installationKnown = useSelector(store => store.form.installationKnown);
    const errors = useSelector(store => store.errors.installationKnown);
    const [state, setState] = useDelayedFormChangeDispatch(installationKnown, onChange);
    const [query, setQuery] = useState(installationKnown?.material?.name || '');
    const productRanges = useSelector(store => store.productRanges);
    const onChangeQuery = value => {
        setQuery(value);
        dispatch(queryMaterials(value));
    };
    const onSelectMaterial = material => {
        setQuery(material.name);
        onChange({ material }); // directly propagate the change or this will needlessly delay the user for 1.4s
        setState({ material });
    };
    const onFocusMaterial = () => dispatch(queryMaterials(query));

    return (
        <>
            <div className='grid-row'>
                <div className='grid-col-12 grid-col-md-6'>
                    <InputWrapper id='material-wrapper' label='installation_indoor_unit' error={errors.material} required>
                        <Typeahead
                            id='material'
                            value={query}
                            suggestions={suggestions}
                            onChangeQuery={onChangeQuery}
                            onSelectSuggestion={onSelectMaterial}
                            onFocus={onFocusMaterial}
                        />
                    </InputWrapper>
                </div>

                <div className='grid-col-12 grid-col-md-6'>
                    <InputWrapper
                        id='serial-number'
                        label='installation_serial_number'
                        error={errors.serial}
                        required
                    >
                        <input
                            type='text'
                            id='serial-number'
                            name='serial-number'
                            value={state.serial}
                            className='form-control'
                            onChange={e => setState({ serial: e.target.value })}
                            required
                        />
                    </InputWrapper>
                </div>
            </div>

            {state.material?.id && (
                <InputWrapper
                    css='form-group form-fields'
                    id='product-range'
                    label='installation_product_range'
                    error={errors.productRange}
                    required
                >
                    <div className='form-fieldset'>
                        {productRanges.length > 0 ? (
                            <div className='grid-row w-100'>
                                {productRanges.map(pr => (
                                    <ProductRange
                                        key={pr.id}
                                        productRange={pr}
                                        isSelected={pr.id === state.productRange?.id}
                                        onSelect={productRange => setState({ productRange })}
                                    />
                                ))}
                            </div>
                        ) : (
                            i18n('loading')
                        )}
                    </div>
                </InputWrapper>
            )}
        </>
    );
};

export default InstallationDetailsViaSerial;
