import React from 'react';
import i18n from '../i18n';

const Details = props => {
    const { companyName, phoneNumber, emailAddress } = props.details;
    return (
        <div className='pb-4'>
            <div><strong>{companyName}</strong></div>
            <div>{phoneNumber}</div>
            {emailAddress && (
                <div><a href={`mailto:${emailAddress}`}>{emailAddress}</a></div>
            )}
        </div>
    );
};

const ContactCard = ({ title, details, isLoading }) => (
    <div className='panel'>
        <h4>{i18n(title)}</h4>
        <div>
            {isLoading ? i18n('loading') : details ? <Details details={details} /> : i18n('no_service_partner')}
        </div>
    </div>
);

export default ContactCard;
