import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import i18n from '../i18n';
import { useDelayedFormChangeDispatch } from '../hooks';
import InputWrapper from './InputWrapper';

const queries = [];
const resultsCache = {};

const AddressForm = ({ id, address, errors = {}, onChange, autoComplete = false }) => {
    const [cities, setCities] = useState(null);
    const [state, setState] = useDelayedFormChangeDispatch(address, onChange);
    // Auto-select first city
    useEffect(() => {
        if (cities && cities.length) setState({ city: cities[0] });
    }, [cities]);
    const country = useSelector(store => store.settings.countryName);
    const url = useSelector(store => store.settings.urls.city);
    // This tag adds the component-id to the fields
    // This prevents different form-sections to influence eachothers autocomplete
    const tag = name => autoComplete ? `${id} ${name}` : `${id}-${name}`;
    // Fetching postalCodes
    const onPostalChange = e => {
        const postal = e.target.value;
        // Clear cities & selected city
        setCities(null);
        setState({ postal, city: '' });
        // Only fetch valid postalCodes which have not been fetched before
        if (url && postal.length >= 3) {
            if (queries.includes(postal)) {
                if (resultsCache[postal]) setCities(resultsCache[postal]);
            } else {
                queries.push(postal);
                axios.get(url.replace('__postalCode__', postal))
                    .then(res => resultsCache[postal] = res.data)
                    .catch(() => resultsCache[postal] = [])
                    .finally(() => setCities(resultsCache[postal]));
            }
        }
    };

    return (
        <>
            <div className='grid-row'>
                <div className='grid-col-12 grid-col-lg-8'>
                    <InputWrapper id='street' label='street' error={errors.street} required>
                        <input
                            type='text'
                            id='street'
                            name={tag`street`}
                            autoComplete={tag`street`}
                            value={state.street || ''}
                            onChange={e => setState({ street: e.target.value })}
                            className='form-control'
                            required
                        />
                    </InputWrapper>
                </div>

                <div className='grid-col-6 grid-col-lg-2'>
                    <InputWrapper id='street' label='street_number' error={errors.number} required>
                        <input
                            type='text'
                            id='street_number'
                            name={tag`street-number`}
                            autoComplete={tag`street-number`}
                            value={state.number || ''}
                            maxLength={5}
                            onChange={e => setState({ number: e.target.value })}
                            className='form-control'
                            required
                        />
                    </InputWrapper>
                </div>

                <div className='grid-col-6 grid-col-lg-2'>
                    <div className='form-group'>
                        <label className='form-label' htmlFor='pobox'>{i18n('pobox')}</label>
                        <input
                            type='text'
                            id='pobox'
                            name={tag`po-box`}
                            autoComplete={tag`po-box`}
                            value={state.poBox || ''}
                            onChange={e => setState({ poBox: e.target.value })}
                            className='form-control'
                        />
                    </div>
                </div>
            </div>

            <div className='grid-row'>
                <div className='grid-col-12 grid-col-lg-8'>
                    <div className='form-group required'>
                        <label className='form-label' htmlFor='postal-code'>{i18n('postal')}</label>
                        <div className='grid-row'>
                            <div className={`grid-col-5 grid-col-lg-4 ${errors.postal ? 'error' : ''}`}>
                                <input
                                    type='text'
                                    id='postal-code'
                                    name={tag`postal-code`}
                                    autoComplete={tag`postal-code`}
                                    value={state.postal || ''}
                                    onChange={onPostalChange}
                                    className='form-control'
                                    required
                                />
                                <small className='text-muted'>
                                    {i18n('postal_format', false)}
                                </small>
                                {errors.postal && <label className='error-msg'>{errors.postal}</label>}
                            </div>
                            <div className={`grid-col-7 grid-col-lg-8 ${errors.postal ? 'error' : ''}`}>
                                {(!url || (cities && cities.length === 0)) ? (
                                    <input
                                        type='text'
                                        name={tag`city`}
                                        autoComplete={tag`city`}
                                        value={state.city || ''}
                                        onChange={e => setState({ city: e.target.value })}
                                        className='form-control'
                                        required
                                    />
                                ) : (
                                    <select
                                        id='city'
                                        name={tag`city`}
                                        value={state.city || ''}
                                        onChange={e => setState({ city: e.target.value })}
                                        className='form-control'
                                        required
                                    >
                                        {cities ? (
                                            <>
                                                <option value='' disabled hidden>
                                                    {i18n('city_select')}
                                                </option>
                                                {cities.map(c => (
                                                    <option key={c} value={c}>
                                                        {c}
                                                    </option>
                                                ))}
                                            </>
                                        ) : (
                                            <option value='' disabled hidden>
                                                {i18n('postal_prompt')}
                                            </option>
                                        )}
                                    </select>
                                )}
                                {errors.city && <label className='error-msg'>{errors.city}</label>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='grid-col-12 grid-col-lg-4'>
                    <div className='form-group'>
                        <label className='form-label' htmlFor='country'>{i18n('country')}</label>
                        <div className=''>
                            <input
                                type='text'
                                id='country'
                                name={tag`country`}
                                autoComplete={tag`country`}
                                value={country || ''}
                                className='form-control-plaintext'
                                readOnly
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddressForm;
