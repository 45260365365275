import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import i18n from '../i18n';
import { setComment } from '../actions';
import { renderComment } from '../selectors';
import { useDelayedPrimitiveChangeDispatch } from '../hooks';
import Guard from '../controls/Guard';

const Comment = () => {
    const dispatch = useDispatch();
    const comment = useSelector(store => store.form.comment);
    const [state, setState] = useDelayedPrimitiveChangeDispatch(comment, c => dispatch(setComment(c)));

    return (
        <section className='form-section'>
            <div className='form-group'>
                <label className='form-label' htmlFor='comment'>{i18n('comment')}</label>
                <textarea
                    id='comment'
                    value={state || ''}
                    placeholder={i18n('comment_placeholder')}
                    onChange={e => setState(e.target.value)}
                    className='form-control'
                    rows={5}
                />
            </div>
        </section>
    );

};

export default () => <Guard selector={renderComment}><Comment /></Guard>;
