/* eslint-disable camelcase */
import React from 'react';
import { useSelector } from 'react-redux';
import i18n from '../i18n';
import { SESSION_USER_TYPES } from '../reducers/settingsReducer';

const alertStyles = {
    with: 'fit-content',
    marginTop: '15px',
};

const ProductRange = ({ productRange, isSelected, onSelect }) => {

    const { id, name, image_path, allows_request_assistance } = productRange;

    const isPro = useSelector(store => store.settings.userType === SESSION_USER_TYPES.PRO);

    // Value of allows_request_assistance is 0 or 1 --> so transforming this to a boolean
    const allowsRequestAssistance = !!allows_request_assistance;

    // Professional does not allow request for assitance for product range
    const profDeniesRequestAssistance = isPro && !allowsRequestAssistance;

    // you should take allowsRequestAssistance value into account for professional-portal
    const isClickable = isPro
        ? allowsRequestAssistance && !isSelected
        : !isSelected;

    return (
        <div className='grid-col-4'>
            <div className='form-check'>
                <label
                    className='form-check-label'
                    onClick={isClickable ? () => onSelect(productRange) : null}
                    htmlFor={id}
                >
                    <input
                        name={id}
                        type='radio'
                        value={id}
                        checked={isSelected}
                        readOnly
                        disabled={profDeniesRequestAssistance}
                    />
                    {name}
                    <img
                        className='product-range-image'
                        alt={id}
                        src={image_path}
                    />
                </label>
                {/* Alert can only be rendered on the professional-portal */}
                {profDeniesRequestAssistance && (
                    <div className='alert alert-warning' style={alertStyles}>
                        {i18n('not_possible_for_product_range')}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ProductRange;
