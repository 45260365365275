import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import i18n from '../i18n';
import { renderLegal } from '../selectors';
import { setLegalAgreementAccepted } from '../actions';
import { SESSION_USER_TYPES } from '../reducers/settingsReducer';
import CloseablePanel from '../controls/CloseablePanel';
import Guard from '../controls/Guard';
import PricingInfo from './PricingInfo';

const keys = [
    'terms_of_use',
    'professional_terms_and_conditions',
    'privacy_statement',
    'data_protection_policy',
];

const Legal = () => {
    const dispatch = useDispatch();
    // Selectors
    const legalAgreementAccepted = useSelector(store => store.form.legalAgreementAccepted);
    const legalAgreementAcceptedError = useSelector(store => store.errors.legalAgreementAccepted);
    const isPro = useSelector(store => store.settings.userType === SESSION_USER_TYPES.PRO);
    const privacyHtml = useSelector(store => store.settings.privacy);
    const termsHtml = useSelector(store => store.settings.terms);
    // State
    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
    const [showPrivacyStatement, setShowPrivacyStatement] = useState(false);
    // Event handlers
    const onLegalClicked = () => dispatch(setLegalAgreementAccepted(!legalAgreementAccepted));
    const linkClicked = key => {
        switch (key) {
            case 'terms_of_use':
            case 'professional_terms_and_conditions':
                setShowTermsAndConditions(!showTermsAndConditions);
                break;
            case 'privacy_statement':
            case 'data_protection_policy':
                setShowPrivacyStatement(!showPrivacyStatement);
                break;
        }
    };

    // Replace {key}-values in the labels with clickable hyperlinks
    const linkify = label => i18n(label)
        .split(/[{}]/g)
        .filter(p => !!p)
        .map(p => {
            if (!keys.includes(p)) return p;
            return (
                <a
                    key={p}
                    href=''
                    onClick={e => {
                        e.preventDefault();
                        linkClicked(p);
                    }}
                >
                    {i18n(p)}
                </a>
            );
        });
    const gdprStatement = isPro ? null : linkify('gdpr_statement');
    const agreementText = linkify(`legal_agreement_${isPro ? 'pro' : 'anon'}`);

    return (
        <section className='form-section'>
            <p>{gdprStatement}</p>
            <div className='form-check'>
                <label className='form-check-label font-normal' htmlFor='legal-agreement'>
                    <input
                        type='checkbox'
                        id='legal-agreement'
                        value='legal-agreement'
                        checked={legalAgreementAccepted}
                        onChange={onLegalClicked}
                        readOnly
                    />
                    {agreementText}
                    {legalAgreementAcceptedError && (
                        <p><label className='error-msg'>{legalAgreementAcceptedError}</label></p>
                    )}
                </label>
            </div>
            {showTermsAndConditions && (
                <CloseablePanel
                    title='terms_and_conditions_title'
                    onClose={() => setShowTermsAndConditions(false)}
                >
                    <div className='legal-text' dangerouslySetInnerHTML={{ __html: termsHtml }} />
                </CloseablePanel>
            )}
            {showPrivacyStatement && (
                <CloseablePanel
                    title='privacy_statement_title'
                    onClose={() => setShowPrivacyStatement(false)}
                >
                    <div className='legal-text' dangerouslySetInnerHTML={{ __html: privacyHtml }} />
                </CloseablePanel>
            )}
            {i18n('invoice_notice')}
            <PricingInfo />
        </section>
    );
};

export default () => <Guard selector={renderLegal}><Legal /></Guard>;
