import React from 'react';
import i18n from '../i18n';
import { useDelayedFormChangeDispatch } from '../hooks';
import InputWrapper from './InputWrapper';

const ContactDetailsForm = ({
    id,
    title,
    details,
    errors = {},
    onChange,
    hasCompany = false,
    hasLegal = false,
    autoComplete = false,
}) => {
    const [state, setState] = useDelayedFormChangeDispatch(details, onChange);
    // This tag adds the component-id to the fields
    // This prevents different form-sections to influence eachothers autocomplete
    const tag = name => autoComplete ? `${id} ${name}` : `${id}-${name}`;
    return (
        <section>
            <h2 className='form-section-title'>{i18n(title || 'contact_details')}</h2>
            {hasCompany && (
                <InputWrapper id='company' label='company' error={errors.company} required>
                    <input
                        type='text'
                        id='company'
                        name={tag`company`}
                        autoComplete={tag`company`}
                        value={state.company || ''}
                        onChange={e => setState({ company: e.target.value })}
                        className='form-control'
                        required
                    />
                </InputWrapper>
            )}
            <div className='grid-row form-fields'>
                <div className='grid-col-12 grid-col-md-6'>
                    <InputWrapper id='given-name' label='first_name' error={errors.firstName} required>
                        <input
                            type='text'
                            id='given-name'
                            name={tag`given-name`}
                            autoComplete={tag`given-name`}
                            value={state.firstName || ''}
                            onChange={e => setState({ firstName: e.target.value })}
                            className='form-control'
                            required
                        />
                    </InputWrapper>
                </div>

                <div className='grid-col-12 grid-col-md-6'>
                    <InputWrapper id='family-name' label='last_name' error={errors.lastName} required>
                        <input
                            type='text'
                            id='family-name'
                            name={tag`family-name`}
                            autoComplete={tag`family-name`}
                            value={state.lastName || ''}
                            onChange={e => setState({ lastName: e.target.value })}
                            className='form-control'
                            required
                        />
                    </InputWrapper>
                </div>
            </div>

            <div className='grid-row form-fields'>
                <div className='grid-col-12 grid-col-md-6'>
                    <InputWrapper id='email' label='email' error={errors.email} required>
                        <input
                            type='email'
                            id='email'
                            name={tag`email`}
                            autoComplete={tag`email`}
                            value={state.email || ''}
                            onChange={e => setState({ email: e.target.value })}
                            className='form-control'
                            required
                        />
                    </InputWrapper>
                </div>

                <div className='grid-col-12 grid-col-md-6'>
                    <InputWrapper id='phone' label='phone' error={errors.phone} required>
                        <input
                            type='tel'
                            id='phone'
                            name={tag`phone`}
                            autoComplete={tag`phone`}
                            value={state.phone || ''}
                            onChange={e => setState({ phone: e.target.value })}
                            className='form-control'
                            required
                        />
                    </InputWrapper>
                </div>
            </div>
            {hasLegal && (
                <div className='form-group required'>
                    <label className='form-label'>{i18n('contact_person_legal_title')}</label>
                    <div className='form-check'>
                        <label className='form-check-label font-normal required' htmlFor='contact_person_legal_1'>
                            <input
                                onChange={() => setState({ legal1: !state.legal1 })}
                                checked={state.legal1 || false}
                                type='checkbox'
                                id='contact_person_legal_1'
                                value='contact_person_legal_1'
                                name='contact_person_legal_1'
                                required
                            />
                            {i18n('contact_person_legal_1')}
                            {errors.legal1 && <label className='error-msg'>{errors.legal1}</label>}
                        </label>
                    </div>
                    <div className='form-check required'>
                        <label className='form-label form-check-label font-normal' htmlFor='contact_person_legal_2'>
                            <input
                                onChange={() => setState({ legal2: !state.legal2 })}
                                checked={state.legal2 || false}
                                type='checkbox'
                                id='contact_person_legal_2'
                                value='contact_person_legal_2'
                                name='contact_person_legal_2'
                                required
                            />
                            {i18n('contact_person_legal_2')}
                            {errors.legal2 && <label className='error-msg'>{errors.legal2}</label>}
                        </label>
                    </div>
                </div>
            )}
        </section>
    );
};

export default ContactDetailsForm;
