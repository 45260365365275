import React from 'react';
import i18n from '../i18n';

const InputWrapper = ({ id, css = 'form-group', label, error, required, children }) => (
    <div className={`${css} ${required ? 'required' : ''} ${error ? 'error' : ''}`}>
        <label className='form-label' htmlFor={id}>
            {i18n(label)}
        </label>
        {children}
        {error && <label className='error-msg'>{error}</label>}
    </div>
);

export default InputWrapper;
