import axios from 'axios';
import { createLogic } from 'redux-logic';
import { ACTIONS, setErrors } from '../actions';
import { getSubmitJson, parseErrors } from '../selectors';
import i18n from '../i18n';

const submitLogic = createLogic({
    type: ACTIONS.SUBMIT,

    process({ getState }, dispatch, done) {
        const url = window.location.href;
        const data = getSubmitJson(getState());
        axios.post(url, data)
            .then(res => {
                if (res.status === 200 && res.data && res.data.redirectUrl) {
                    window.location = res.data.redirectUrl;
                } else {
                    dispatch(setErrors({ submit: i18n('unexpected_error') }));
                }
            })
            .catch(err => {
                const errors = err?.response?.data?.errors;
                if (errors) dispatch(setErrors(parseErrors(errors)));
            })
            .finally(done);
    },
});

export default [submitLogic];
